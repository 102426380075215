import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import React from "react";
import { Button, Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const TagTemplate = ({ data: { posts, tag, heroInterior, site, tags } }) => {
	const siteUrl = site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Resources",
				item: {
					url: `${siteUrl}/resources`,
					id: `${siteUrl}/resources`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `Tags`,
				item: {
					url: `${siteUrl}/resources/tags`,
					id: `${siteUrl}/resources/tags`,
				},
			},
			{
				"@type": "ListItem",
				position: "4",
				name: `${tag.name}`,
				item: {
					url: `${siteUrl}/resources/tags/${tag.slug}`,
					id: `${siteUrl}/resources/tags/${tag.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${tag.name} | Flex Office Solutions`}
				description={tag.name}
				language="en"
				openGraph={{
					type: "article",
					url: `${siteUrl}/resources/tags/${tag.slug}`,
					title: `${tag.name} | Flex Office Solutions`,
					description: `${tag.name}`,
					images: [
						{
							url: `${heroInterior?.localFile.publicURL}`,
							width: `${heroInterior?.localFile.childImageSharp.original.width}`,
							height: `${heroInterior?.localFile.childImageSharp.original.height}`,
							alt: `${heroInterior?.altText}`,
						},
					],
				}}
			/>

			<div style={{ overflow: "hidden" }}>
				<section className="bg-primary bg-opacity-10 py-4 py-lg-5 mb-xl-3 mb-2">
					<Container>
						<Row>
							<Col className="text-center">
								<p className="text-med-grey fw-bold">OUR LATEST POSTS</p>
								<h1>
									{tag.name
										.split(" ")
										.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
										.join(" ")}
								</h1>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="py-2 py-lg-3">
					<Container>
						<Row>
							<div className="d-flex justify-content-center">
								<ul className="list-unstyled text-center">
									{tags.nodes.map((mapTag) => (
										<Link to={`/resources/tags/${mapTag.slug}`}>
											<li
												key={mapTag.id}
												className={`d-inline-block py-1 px-3 me-3 mb-2 fw-light text-center ${
													tag.name === mapTag.name
														? "bg-dark-blue text-white border border-1 border-white"
														: "border border-1 border-med-grey text-med-grey"
												} rounded-pill`}
											>
												{mapTag.name}
											</li>
										</Link>
									))}
									<Link to={`/resources`}>
										<li
											className={`d-inline-block py-1 px-3 me-3 mb-2 fw-light text-center bg-primary text-white border border-1 border-white rounded-pill`}
										>
											See all
										</li>
									</Link>
								</ul>
							</div>
						</Row>
					</Container>
				</section>
				<section className="pt-2 pt-lg-3 pb-5">
					<Container>
						<Row className="g-xl-6 g-4 h-100 ">
							{posts.nodes.map((post) => (
								<Col
									style={{ minHeight: "100%", borderRadius: "10px" }}
									className=""
									md={6}
									lg={4}
								>
									<Card className="h-100 position-relative">
										<Card.Img
											as={GatsbyImage}
											style={{ height: "250px" }}
											variant="top"
											image={
												post.blogFields.featuredImage.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={post.blogFields.featuredImage.altText}
										/>
										<Card.Body className="p-3 pb-6">
											{post.blogFields?.minsToRead && (
												<p className="pt-0 my-0 text-dark-blue pb-2 d-md-none fw-bold">
													{post.blogFields.minsToRead} min read
												</p>
											)}
											<Card.Title className="text-primary">
												{post.title}
											</Card.Title>
											<Card.Text>{parse(post.excerpt)}</Card.Text>

											<div className="position-absolute align-items-end d-flex justify-content-between w-100 bottom-0 start-0 p-3">
												<Button
													as={Link}
													to={`/resources/${post.slug}`}
													className="px-3  w-100 w-md-auto text-white mt-1"
													variant="primary"
												>
													{" "}
													Read More
												</Button>
												{post.blogFields?.minsToRead && (
													<p className="py-0 my-0 text-primary d-none d-md-inline-block fw-bold">
														{post.blogFields.minsToRead} min read
													</p>
												)}
											</div>
										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>
					</Container>
				</section>
			</div>
		</Layout>
	);
};

export default TagTemplate;

export const pageQuery = graphql`
	query TagById($id: String!) {
		heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		tag: wpTag(name: { eq: $id }) {
			name
			slug
		}
		posts: allWpPost(
			sort: { fields: dateGmt, order: DESC }

			filter: {
				tags: { nodes: { elemMatch: { name: { eq: $id } } } }
				title: { ne: "Website Images" }
			}
		) {
			nodes {
				slug
				excerpt
				title
				dateGmt
				blogFields {
					minsToRead
					featuredImage {
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
						altText
					}
				}
			}
		}
		tags: allWpTag(filter: { count: { ne: 0 } }) {
			nodes {
				name
				slug
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
